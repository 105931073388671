import * as React from 'react';

// import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import {
  ButtonFullSizeWrapper,
  MuteButton,
  MuteButtonWrapper,
  PlayButton,
  ReplayButton,
} from '../button';
import { useIsMobile } from '../hooks/useIsMobile';

interface IController {
  featureAppId: string;
  isMuted: boolean;
  // play: Pick<AudiVideoServiceInterfaceV1, 'play'>;
  play: () => Promise<void>;
  // replay: Pick<AudiVideoServiceInterfaceV1, 'play'>;
  replay: (consumerId?: string) => Promise<void>;
  showPlayButton: boolean;
  showReplayButton: boolean;
  showToggleMuteButton: boolean;
  toggleMuted: () => void;
  groupButtonsMobile: boolean;
}

export const Controller: React.FC<IController> = ({
  featureAppId,
  isMuted,
  play,
  replay,
  showPlayButton,
  showReplayButton,
  showToggleMuteButton,
  toggleMuted,
  groupButtonsMobile,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      {showPlayButton && (
        <ButtonFullSizeWrapper>
          <PlayButton
            onClick={() => {
              // eslint-disable-next-line no-void
              void play();
            }}
            groupButtonsMobile={isMobile && groupButtonsMobile}
          />
          {groupButtonsMobile && showToggleMuteButton && isMobile && (
            <MuteButton isMuted={isMuted} toggleMuted={toggleMuted} />
          )}
        </ButtonFullSizeWrapper>
      )}
      {showReplayButton && (
        <ButtonFullSizeWrapper>
          <ReplayButton
            onClick={() => {
              // eslint-disable-next-line no-void
              void replay();
            }}
          />
        </ButtonFullSizeWrapper>
      )}
      {showToggleMuteButton && !isMobile && (
        <MuteButtonWrapper id={`${featureAppId}-videoAppMuteButtonWrapper`}>
          <MuteButton isMuted={isMuted} toggleMuted={toggleMuted} />
        </MuteButtonWrapper>
      )}
    </>
  );
};
