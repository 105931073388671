import * as React from 'react';
import styled from 'styled-components';

import { IconButton, IconButtonProps, IconProps } from '@oneaudi/unified-web-components';
import { SYS_BREAKPOINT_LG, SYS_SPACE_RELATIVE_BP_XS_XS } from '@oneaudi/unified-web-common';

const ThemedButton = styled.div`
  background: none;
  border: 0 none;
  cursor: pointer;
  display: inline-block;
`;

const ToggleWrapper = styled.div`
  .large {
    display: none;
  }

  @media screen and (min-width: ${SYS_BREAKPOINT_LG}) {
    .small {
      display: none;
    }

    .large {
      display: block;
    }
  }
`;

const ToggleSoundSVG: React.FC<{
  isMuted: boolean;
  toggleMuted: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ isMuted, toggleMuted }) => {
  const iconButtonProps = {
    icon: isMuted ? 'audio-volume-off' : ('audio-volume' as IconProps['name']),
    variant: 'primary' as IconButtonProps['variant'],
    onClick: toggleMuted,
    'aria-label': isMuted ? 'Unmute' : 'Mute',
  };

  return (
    <ToggleWrapper>
      <IconButton {...iconButtonProps} size="md" className="large" type="button" />
      <IconButton {...iconButtonProps} size="sm" className="small" type="button" />
    </ToggleWrapper>
  );
};

export const PlayButton: React.FC<{
  onClick?: () => void;
  groupButtonsMobile?: boolean;
}> = ({ onClick, groupButtonsMobile = false }) => {
  return (
    <IconButton
      icon="play"
      size={groupButtonsMobile ? 'sm' : 'md'}
      variant="primary"
      onClick={onClick}
      aria-label="play button"
    />
  );
};

export const ReplayButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  return <IconButton icon="replay" onClick={onClick} size="md" variant="primary" />;
};

export const MuteButtonWrapper = styled.div`
  bottom: 0;
  position: absolute;
  right: 4%;
  z-index: 3;

  margin-block-end: var(--spacing-relative-xl);
`;

export const MuteButton: React.FC<{
  toggleMuted: () => void;
  isMuted: boolean;
}> = (props) => {
  const { toggleMuted, isMuted } = props;

  return (
    <ThemedButton>
      <ToggleSoundSVG isMuted={isMuted} toggleMuted={toggleMuted} />
    </ThemedButton>
  );
};

export const ButtonFullSizeWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  && > div:first-child:nth-last-child(2) {
    margin-right: ${SYS_SPACE_RELATIVE_BP_XS_XS};
  }
`;
