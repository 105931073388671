const percentageMarks: { [key: number]: boolean } = { 25: true, 50: true, 75: true, 95: true };
export function getPercentageToTrack(currentTime: number, duration: number): number | null {
  if (duration < 10) {
    return null;
  }

  const percent = Math.round((currentTime / duration) * 100);

  if (percentageMarks[percent]) {
    percentageMarks[percent] = false;
    return percent;
  }
  return null;
}
