/* eslint-disable no-restricted-syntax */
const enum QueryParam {
  Debug = 'd_fa',
  NoTracking = 'notrack_fa',
}

const getParam = (paramName: string): string | null => {
  try {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search.substr(1)).get(paramName);
    }
  } catch {
    return null;
  }
  return null;
};

export const isDebugMode = (): boolean => getParam(QueryParam.Debug) === 'true';
export const isTrackingDisabled = (): boolean => getParam(QueryParam.NoTracking) === 'true';

export const debug = (debuggingMessage: string) =>
  Boolean(typeof window !== 'undefined' && isDebugMode()) &&
  // eslint-disable-next-line no-console
  console.log({ __FEATURE_APP_NAME__ }, debuggingMessage);

export function logVideoPlayerComponentTrackingData(prefix?: string) {
  if (isDebugMode()) {
    if (prefix) {
      debug(`${prefix} fa-video-player tracking components:`);
    }

    if (typeof window !== 'undefined') {
      const filteredComponents = window?.digitalData?.component?.filter(
        (c) =>
          (c.componentInfo.componentID &&
            c.componentInfo.componentID.indexOf('video-player') !== -1) ||
          (c.componentInfo.componentName &&
            c.componentInfo.componentName.indexOf('video-player') !== -1),
      );
      if (filteredComponents && filteredComponents.length > 0) {
        filteredComponents.forEach((c, i) => {
          debug(`   component #${i}:  ${JSON.stringify(c.componentInfo)}`);
        });
      } else {
        debug('   none');
      }
    }
  }
}

export function observeChildren(parentNode: HTMLElement) {
  if (isDebugMode()) {
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (mutation.removedNodes.length > 0) {
            debug(`These child nodes have been removed: ${JSON.stringify(mutation.removedNodes)}`);
          }
          if (mutation.addedNodes.length > 0) {
            debug(`These child nodes have been added: ${JSON.stringify(mutation.addedNodes)}`);
          }
        }
      }
    });

    debug(`Observing: ${JSON.stringify(parentNode)}`);
    observer.observe(parentNode, { childList: true });
  }
}
